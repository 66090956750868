<template>
  <v-dialog
    v-model="dialogNewEmail"
    max-width="70%"
  >
    <v-card>
      <v-toolbar
        color="blue"
        dark
      >
        <v-toolbar-title>Nouvel e-mail</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialogNewEmail = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text
          class="px-16 pt-4"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                solo
                dense
                hide-details
                prefix="De : "
                v-model="mail.expediteur"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
            >
            <v-select
              solo
              dense
              hide-details
              multiple
              item-value="email"
              v-model="mail.destinataires"
              :items="contacts"
              prepend="À : "
              >
              <template v-slot:item="{item, attrs, on}">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                >
                  <v-list-item-action>
                    <v-checkbox
                      :ripple="false"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row
                        no-gutters
                        align="center"
                      >
                        {{ item.prenom }} {{ item.nom }} &lt;{{ item.email }}&gt;
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                v-slot:prepend-inner
              >
                <span style="width: 1.3em">À :</span>
              </template>
              <template
                v-slot:selection="{ item }"
              >
                <v-chip>
                  {{ item.prenom }} {{ item.nom }} &lt;{{ item.email }}&gt;
                </v-chip>
              </template>
            </v-select>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                solo
                dense
                hide-details
                prefix="Objet : "
                v-model="mail.objet"
              />
            </v-col>
            <v-col
              cols="12"
            >
            <tiptap-vuetify
              v-model="mail.contenu"
              :extensions="extensions"
            />
            </v-col>
            <v-col
              cols="12"
              v-if="attachementName"
            >
              <v-alert
                dense
                icon="mdi-paperclip"
                text
                color="grey"
                class="py-0"
              >
                <span class="bold">{{ attachementName }}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="px-16 pt-0 pb-4"
        >
          <v-row>
            <v-col
              class="align-right"
            >
              <v-btn
                color="blue darken-1"
                @click="send()"
              >
                Envoyer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapMutations } from 'vuex';
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {

    name: 'DialogNewEmail',

    components: { TiptapVuetify },

    data() {
      return {
        dialogNewEmail: false,
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3],
            },
          }],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak,
        ],
        typeEmail: null,
        formation: {},
        client: {},
        contacts: [],
        mail: {
          expediteur: '',
          destinataires: [],
          objet: '',
          contenu: '',
        },
        attachementName: null,
        infos: {},
      }
    },

    methods: {
      show(typeEmail, infos) {
        this.typeEmail = typeEmail;
        this.infos = infos;

        this.axios.get('configuration/getValue/email').then(res => { this.mail.expediteur = res.data });

        this.dialogNewEmail = true;

        switch (typeEmail) {
          case 'CONVENTION':
            this.mail.objet = 'Convention de formation';
            this.contacts = infos.client.contactsAdministratif;
            this.attachementName = 'Convention de formation.pdf';
            break;
          case 'DEVIS':
            this.mail.objet = 'Votre devis';
            this.contacts = infos.devis.clientDestinataire.contactsCommercial;
            this.attachementName = 'Devis.pdf';
            break;
          case 'FACTURE':
            this.mail.objet = 'Votre facture';
            this.contacts = infos.facture.destinataire.contactsFacturation;
            this.attachementName = 'Facture.pdf';
        }

        // Sélectionner tous les contacts par défaut
        this.mail.destinataires = this.contacts.map(c => c.email);
      },

      send() {
        const params = { type: this.typeEmail };
        switch (this.typeEmail) {
          case 'CONVENTION':
            params.formation = this.infos.formation.id;
            params.client = this.infos.client.id;
            break;
          case 'DEVIS':
            params.devis = this.infos.devis.id;
            break;
          case 'FACTURE':
            params.facture = this.infos.facture.id;
            break;
        }
        this.$emit('mailEnvoiEnCours');
        this.axios.post('/mails/send', this.mail, { params: params })
          .then((res) => {
            this.setSnack({ text: 'Mail envoyé', type: 'success' });
            this.$emit('mailEnvoye');
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'envoi du mail', type: 'warn' });
          });
        this.closeDialog();
      },

      closeDialog() {
        this.dialogNewEmail = false;
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),

    },

  }
</script>

<style scoped>
  .bold {
    color: #1f89e6;
    font-size: inherit !important;
    font-weight: bold !important;
  }
</style>
