var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},model:{value:(_vm.dialogNewEmail),callback:function ($$v) {_vm.dialogNewEmail=$$v},expression:"dialogNewEmail"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"blue","dark":""}},[_c('v-toolbar-title',[_vm._v("Nouvel e-mail")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogNewEmail = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-form',[_c('v-card-text',{staticClass:"px-16 pt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"solo":"","dense":"","hide-details":"","prefix":"De : ","disabled":""},model:{value:(_vm.mail.expediteur),callback:function ($$v) {_vm.$set(_vm.mail, "expediteur", $$v)},expression:"mail.expediteur"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"solo":"","dense":"","hide-details":"","multiple":"","item-value":"email","items":_vm.contacts,"prepend":"À : "},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(item.prenom)+" "+_vm._s(item.nom)+" <"+_vm._s(item.email)+"> ")])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticStyle:{"width":"1.3em"}},[_vm._v("À :")])]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.prenom)+" "+_vm._s(item.nom)+" <"+_vm._s(item.email)+"> ")])]}}]),model:{value:(_vm.mail.destinataires),callback:function ($$v) {_vm.$set(_vm.mail, "destinataires", $$v)},expression:"mail.destinataires"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"solo":"","dense":"","hide-details":"","prefix":"Objet : "},model:{value:(_vm.mail.objet),callback:function ($$v) {_vm.$set(_vm.mail, "objet", $$v)},expression:"mail.objet"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions},model:{value:(_vm.mail.contenu),callback:function ($$v) {_vm.$set(_vm.mail, "contenu", $$v)},expression:"mail.contenu"}})],1),(_vm.attachementName)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"py-0",attrs:{"dense":"","icon":"mdi-paperclip","text":"","color":"grey"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.attachementName))])])],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"px-16 pt-0 pb-4"},[_c('v-row',[_c('v-col',{staticClass:"align-right"},[_c('v-btn',{attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.send()}}},[_vm._v(" Envoyer ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }